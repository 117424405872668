import { useState, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as MUI from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { debounce } from '@mui/material/utils';

import IconChat from '@mui/icons-material/Chat';
import IconMenu from '@mui/icons-material/Menu';
import IconSearch from '@mui/icons-material/Search';
import IconModeLight from '@mui/icons-material/LightMode';
import IconModeSystem from '@mui/icons-material/SettingsBrightness';
import IconModeDark from '@mui/icons-material/DarkModeOutlined';

import { GlobalContext } from './GlobalContext';
import api from './api';

const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginRight: theme.spacing(2),
	marginLeft: 0,
	flexGrow: 1,
	[theme.breakpoints.up('sm')]: {
		marginLeft: theme.spacing(3),
		width: 'auto',
	},
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const SearchInput = styled(MUI.TextField)(({ theme }) => ({
	color: 'inherit',
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		width: '100%',
	},
}));

export default function Toolbar(props) {
	const location = useLocation();
	const navigate = useNavigate();
	const globalContext = useContext(GlobalContext);
	const [userMenu, setUserMenu] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [searchOptions, setSearchOptions] = useState([]);

	const doSearch = useMemo(() => {
		return debounce(async (query) => {
			const res = await api.call('core/search', { search: query });
			if (res.result) {
				setSearchOptions(res.result);
			} else {
				setSearchOptions([]);
			}
		}, 500);
	}, []);

	useEffect(() => {
		if (searchQuery !== "") {
			doSearch(searchQuery);
		} else {
			setSearchOptions([]);
		}
	}, [searchQuery]);

	return (<MUI.Toolbar>
		<MUI.IconButton size="large" edge="start" color="inherit" aria-label="menu"
			sx={{ mr: 1, display: { xs: 'block', md: 'none' } }}
			onClick={() => navigate('?leftMenu', { state: location.state })}
		><IconMenu /></MUI.IconButton>
		<MUI.Typography variant="h6" noWrap component="div" sx={{ width: '200px', display: { xs: 'none', sm: 'block' } }}>
			{globalContext.brand}
		</MUI.Typography>
		<Search>
			<MUI.Autocomplete
				size="small"
				inputValue={searchQuery} onInputChange={(e, val) => setSearchQuery(val)}
				value="" onChange={(e, val, reason) => {
					if (reason === 'selectOption') {
						if ((val.module === "billing") && (val.object === "user")) {
							navigate("/billing/user/dashboard/" + val.id);
						}
					}
				}}
				options={searchOptions}
				freeSolo
				renderInput={(params) => <SearchInput placeholder='Поиск' {...params} />}
				filterOptions={(x) => x}
				getOptionLabel={(option) => ''}
				renderOption={(props, option) => {
					return (<li {...props}>
						<MUI.Stack>
							<MUI.Typography>
								{option.name}
							</MUI.Typography>
							<MUI.Typography variant="body2" color="text.secondary">
								{option.description}
							</MUI.Typography>
						</MUI.Stack>
					</li>);
				}}
			/>
		</Search>
		<MUI.Box>
			<MUI.Tooltip title="Чат">
				<MUI.IconButton size="large" color="inherit" onClick={(e) => navigate('/chat/messages')}>
					<IconChat />
				</MUI.IconButton>
			</MUI.Tooltip>
			<MUI.Tooltip title={props.account.name}>
				<MUI.IconButton sx={{ pr: 0 }} onClick={(e) => setUserMenu(e.currentTarget)}>
					<MUI.Avatar alt={props.account.name} src={"/api/core/user.profile.avatar?"+props.account.login} />
				</MUI.IconButton>
			</MUI.Tooltip>
			<MUI.Menu
				anchorEl={userMenu}
				open={userMenu !== false}
				onClose={() => setUserMenu(false)}
			>
				<MUI.Box sx={{ p: 1 }}>
					{props.account.name}
				</MUI.Box>
				<MUI.ButtonGroup variant="outlined" fullWidth={true} sx={{ px: 1, pb: 1 }}>
					<MUI.Tooltip title="Светлая тема">
						<MUI.Button
							variant={props.themeMode === 'light' ? "contained" : "outlined"}
							onClick={() => props.setThemeMode('light')}>
							<IconModeLight />
						</MUI.Button>
					</MUI.Tooltip>
					<MUI.Tooltip title="Автоопределение темы">
						<MUI.Button
							variant={props.themeMode === 'system' ? "contained" : "outlined"}
							onClick={() => props.setThemeMode('system')}>
							<IconModeSystem />
						</MUI.Button>
					</MUI.Tooltip>
					<MUI.Tooltip title="Темная тема">
						<MUI.Button
							variant={props.themeMode === 'dark' ? "contained" : "outlined"}
							onClick={() => props.setThemeMode('dark')}>
							<IconModeDark />
						</MUI.Button>
					</MUI.Tooltip>
				</MUI.ButtonGroup>
			</MUI.Menu>
		</MUI.Box>
	</MUI.Toolbar>)
}
