import { createContext, useState } from 'react';

const defaultValue = {
	displayName: 'На текущий момент',
	projectId: 0,
	commitId: 0
};

export const NetworkContext = createContext(defaultValue);

export const NetworkContextProvider = (props) => {
	const [contextValue, setContextValue] = useState(defaultValue);

	contextValue.set = (name, projectId = 0, commitId = 0) => {
		setContextValue({
			displayName: name,
			projectId: projectId,
			commitId: commitId
		});
	};

	return (<NetworkContext.Provider value={contextValue}>{props.children}</NetworkContext.Provider>);
}
