
const API_URL = '/api/';

let apiToken = '';
let ws;

const api = {};
const listeners = {};

function wsConnect() {
	const proto = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
	ws = new WebSocket(proto + window.location.host + '/api/ws/')
	ws.onclose = () => {
		ws = undefined
	}
	ws.onerror = (err) => {
		console.log('WebSocket error: ', err.message)
		ws.close();
	}
	ws.onmessage = (e) => {
		const data = JSON.parse(e.data);
		if ((data.event !== undefined) && (listeners[data.event] !== undefined)) {
			listeners[data.event](data);
		}
	}
	ws.onopen = () => {
		ws.send(JSON.stringify({
			token: apiToken
		}));
	}
}

api.call = async function(name, params = {}) {
	const headers = await fetch(API_URL + name, {
		method: 'POST',
		body: JSON.stringify(params),
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + apiToken
		}
	});
	const body = await headers.json();
	if (body.error) {
		api.showError(body.error);
		throw new Error(body.error);
	}
	return body;
}

api.registerListener = (eventName, eventListener) => {
	listeners[eventName] = eventListener
	if (ws === undefined) {
		wsConnect();
	}
}

api.setToken = (token) => {
	apiToken = token;
}

api.unregisterListener = (eventName) => {
	listeners[eventName] = undefined
}

export default api;
