import { useEffect } from 'react';
import * as MUI from '@mui/material';

export default function LoginForm(props) {

	useEffect(() => {
		document.getElementById('login').focus();
	}, []);

	const onLogin = (e) => {
		e.preventDefault();
		const login = document.getElementById('login').value;
		const password = document.getElementById('pass').value;
		props.start({ login: login, password: password });
	}

	return (
	<MUI.Box display="flex" alignItems="center" justifyContent="center" width="100%" minHeight="100vh">
		<MUI.Paper elevation={10}>
			<MUI.Box sx={{ p: 2 }}>MultiAdmin</MUI.Box>
			<MUI.Divider />
			<MUI.Box sx={{ p: 2 }}>
				<form onSubmit={onLogin}>
					<div><MUI.TextField sx={{ my: 2 }} id="login" label="Логин" /></div>
					<div><MUI.TextField sx={{ my: 2 }} id="pass" label="Пароль" type="password" /></div>
					<MUI.Button variant="contained" type="submit" fullWidth={true}>Вход</MUI.Button>
				</form>
			</MUI.Box>
		</MUI.Paper>
	</MUI.Box>);
}
